.credit-button--container {
    .dropdown-toggle {
        &.credit-button {
            &.noCredits {
                background-color: #888888 !important;
                &:hover {
                    background-color: #888888 !important;
                }
            }

            &:after {
                content: none;
            }

            display: flex;
            align-items: center;
            gap: 8px;

            padding: 0 5px !important;
            height: 55px;

            border-radius: 100px !important;
            background-color: var(--primary-button-background-color) !important;

            aspect-ratio: 1/1;

            transition: background-color 0.3s ease, transform 0.3s ease;

            &:hover {
                background-color: var(
                    --primary-button-background-color--hover
                ) !important;
            }

            .credit-button--points-label {
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                padding-top: 1px;
                color: var(--primary-button-text-color);
            }
        }
    }
}

.credit-dropdown--content {
    display: flex;
    flex-direction: column;
    padding: 8px;

    border-radius: var(--card-border-radius, 3px);
    box-shadow: 0 3px 9px var(--secondary-button-box-shadow-color) !important;

    gap: 8px;

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;

        gap: 8px;

        padding-bottom: 5px;
        border-bottom: 1px solid var(--card-divider-color);

        &--label {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
        }
    }

    &--list {
        font-family: var(--font-family-body);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 8px;

        .credit-column {
            display: flex;
            flex-direction: column;

            .credit-category,
            .credit-point,
            .credit-separator {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
            }
        }
    }
}

.credit-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &--label {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-anchor: middle;
        dominant-baseline: middle;

        &--text {
            textPath {
                font-size: 19px;
                font-weight: bold;
                fill: white;
            }
        }
    }

    &--points-label {
        font-size: 2rem;
        font-weight: bold;
        color: white;
    }
}
